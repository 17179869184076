import { navigate } from 'gatsby-link';
import React, { useEffect, useState } from 'react';

const SearchBar = ({ handleSubmit, numObits }) => {
  const [searchValue, setSearchValue] = useState('');

  return (
    <>
      <div className='w-2/3 mx-auto my-4'>
        <form
          className='form-input'
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            handleSubmit(searchValue);
          }}
        >
          <div className='grid grid-cols-5'>
            <div className='col-start-1 col-span-4'>
              <input
                className='form-input w-11/12'
                type='text'
                placeholder='Search by name'
                name='Obituary Search'
                value={searchValue}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  setSearchValue(e.currentTarget.value);
                }}
              />
            </div>
            <div className='col-start-5 col-span-1'>
              <input
                type='submit'
                className='py-2 px-4 bg-green-700 hover:bg-green-600 text-base text-white font-bold uppercase rounded shadow-md hover:-translate-1 cursor-pointer'
                value='Search'
              />
            </div>
          </div>
        </form>
        <div className='grid grid-cols-5'>
          <div className='col-start-2 col-span-2 items-center'>
            <p className=' pt-3 mx-auto text-xs text-gray-400'>
              {numObits} obituaries since August 2021
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBar;
