import { Link, graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFlexSearch } from 'react-use-flexsearch';

import banner from '../../assets/images/leafBannerSmaller.jpg';
import Guarantee from '../../components/guarantee';
import Layout from '../../components/layout';
import SearchBar from '../../components/search-bar';
import SEO from '../../components/seo';
import Services from '../../components/services';

const SearchPage = ({
  location,
  data: {
    localSearchObits: { index, store },
  },
}) => {
  const [query, setQuery] = useState(null);
  const results = useFlexSearch(query, index, store);

  useEffect(() => {
    setQuery(new URLSearchParams(location.search).get('q'));
    console.log('fired');
  }, [location.search]);
  const onSubmit = async (v) => {
    setQuery(v);
  };
  return (
    <Layout
      headerClass='relative bg-white'
      hasHero={false}
      heroSize='20rem'
      heroImage={banner}
    >
      <SEO
        title='Obituaries | City of Oaks Cremation & Funeral Home'
        description='We help families honor their loved ones every day. City of Oaks Cremation is a full-service funeral home in Raleigh serving all of NC and beyond.'
      />
      {/* <form className='form-input' onSubmit={handleSubmit(onSubmit)}>
        <label className='block'>
          <span className='text-gray-700'>Type obituary here:</span>
          <input
            {...register('query')}
            type='text'
            name='query'
            placeholder=''
          />
          <input
            className='mt-4 py-2 px-4 bg-green-700 hover:bg-green-600 text-base text-white font-bold uppercase rounded shadow-md hover:-translate-1 cursor-pointer'
            type='submit'
            value='Search'
          />
        </label>
      </form> */}
      <h1 className='mb-2 text-4xl text-gray-800 self-center text-center mt-10'>
        Obituary Search
      </h1>
      <div className='mt-4'>
        <SearchBar
          handleSubmit={onSubmit}
          numObits={Object.keys(store).length}
        />
      </div>

      <div className='flex flex-col mt-10 md:mt-16 mb-10'>
        {query ? (
          results.length ? (
            <>
              <div className='w-2/3 mx-auto'>
                <h2 className='text-center text-2xl text-gray-800'>Results</h2>
                <ul>
                  {results.map(
                    ({ slug, name, content, city, state, deathdate, age }) => {
                      return (
                        <>
                          <li>
                            <div className='my-4 text-gray-800 text-sm'>
                              <Link to={`/obituaries/${slug}`}>
                                <span className='pb-2 text-xl text-green-700 hover:text-green-800 hover:underline font-serif'>
                                  {name}
                                </span>
                                <br />
                                <span>
                                  {city}, {state}
                                </span>
                                <br />
                                <span>Age {age}</span>
                                <br />
                                <span>Died {deathdate}</span>
                              </Link>
                            </div>
                          </li>
                        </>
                      );
                    }
                  )}
                </ul>
              </div>
            </>
          ) : (
            <p className='text-center'>Sorry, no results for that search.</p>
          )
        ) : (
          <p className='text-center'>Enter a name above to search.</p>
        )}
      </div>
      <div className='w-2/3 mx-auto text-left'>
        <p className='pb-4'>
          We’re honored to offer every family that chooses City of Oaks
          Cremation and Funeral Home a shareable online obituary to celebrate
          their loved ones free of charge.
        </p>
        <p className='pb-4'>
          Simply having to reach out to a funeral home can feel overwhelming.
          Our goal is to simplify the entire funeral home experience and make
          the process as easy as possible. We hope to eliminate any unnecessary
          surprises by publishing our total cost and the services we provide
          directly on our website.
        </p>
        <p className='mb-4'>
          City of Oaks Cremation and Funeral Home is a full-service funeral home
          in Raleigh, serving all of North Carolina and beyond. We provide an
          affordable alternative to costly funeral homes and every day we help
          families in Raleigh, Durham, Chapel Hill and all over North Carolina
          make simple, affordable funeral arrangements.
        </p>
      </div>
      <Guarantee />
      <Services />
    </Layout>
  );
};

export const query = graphql`
  query SearchPageQuery {
    localSearchObits {
      index
      store
    }
  }
`;

export default SearchPage;
